// extracted by mini-css-extract-plugin
export var wrapper = "mainNav-module--wrapper--z2hLH";
export var desktopOnly = "mainNav-module--desktop-only--1QbTX";
export var menuLinkDecorator = "mainNav-module--menu-link-decorator--1i6hc";
export var menuLinkActive = "mainNav-module--menu-link-active--t-jqR";
export var menuLink = "mainNav-module--menu-link--dWcQy";
export var langButtonWrapper = "mainNav-module--lang-button-wrapper--1mL0-";
export var langButton = "mainNav-module--lang-button--3h8pi";
export var langMenu = "mainNav-module--lang-menu--2mH87";
export var decorator = "mainNav-module--decorator--2_K6G";
export var activeLang = "mainNav-module--active-lang--3ofz4";
export var mobileOnly = "mainNav-module--mobile-only--3Fepz";
export var mobileNavToggler = "mainNav-module--mobile-nav-toggler--1tNJQ";
// extracted by mini-css-extract-plugin
export var wrapper = "mobileNav-module--wrapper--1qMVp";
export var closeButton = "mobileNav-module--close-button--fSvqS";
export var mobileLangMenuCloseButton = "mobileNav-module--mobile-lang-menu-close-button--1bRrJ";
export var mobileNav = "mobileNav-module--mobile-nav--3MCCE";
export var mobileLangMenu = "mobileNav-module--mobile-lang-menu--3N3XA";
export var menuLinkDecorator = "mobileNav-module--menu-link-decorator--KJy2p";
export var menuLinkActive = "mobileNav-module--menu-link-active--KMkIm";
export var menuLink = "mobileNav-module--menu-link--3S6FJ";
export var langButton = "mobileNav-module--lang-button--3fj4Z";
export var activeLang = "mobileNav-module--active-lang--afeXD";